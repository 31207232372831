.modal-order_add {
  input {
    font-size: 14px;
  }
  .ant-select {
    width: 100%;
    font-size: 14px;
  }
  .buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    button {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 24px;
      &:not(:last-of-type) {
        margin-right: 24px;
      }
      svg {
        margin-right: 8px;
      }
      &.button-submit {
        border: 1px solid #37c936;
        color: darken(#37c936, 10%);
      }
    }
  }
}