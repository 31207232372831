.main-content {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  max-width: calc(100vw - 80px);
  .tabs-page {
    flex: 1 0 auto;
  }
}

main {
  .preloader-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, .8);
    z-index: 10000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s, visibility 0s 0.25s;
    &.overlay_active {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.25s;
    }
  }
  .table-spinner_loading {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .error-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 88px);
    justify-content: center;
    align-items: center;
    padding: 24px;
    img {
      max-width: 100%;
      height: auto;
      margin-bottom: 48px;
    }
    h2 {
      color: #2a4558;
      font-size: 72px;
      font-weight: 600;
      line-height: 72px;
      margin-bottom: 24px;
    }
    p {
      color: rgba(0, 0, 0, .5);
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 24px;
    }
  }
  &.container-common {
    display: flex !important;
    overflow: hidden;
    max-width: 100vw;
    .main-content {
      background-color: #f4f6f9;
      display: flex;
      flex: 1;
    }
  }
  .tabs-container {
    padding: 12px 24px;
    height: 100%;
    .ant-tabs {
      height: 100%;
      max-height: calc(100vh - 92px);
      .ant-tabs-nav {
        .ant-tabs-tab {
          color: #767c8c;
        }
        .ant-tabs-tab-active {
          color: #433637;
        }
      }
      .ant-tabs-ink-bar {
        background: rgb(219, 26, 36);
      }
    }
  }
  .overlay {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, .5);
  }
  .order-button_add {
    background: #37c936;
    //background-image: -webkit-linear-gradient(169deg, #ed515f, #ff6f33);
    //background-image: -o-linear-gradient(169deg, #ed515f, #ff6f33);
    //background-image: linear-gradient(281deg, #ed515f, #ff6f33);
    color: #fff;
    border-radius: 4px;
    outline: none;
    padding: .75rem 2rem;
    text-transform: none;
    font-size: 16px;
    font-weight: 400;
    transition: all .2s;
    &:hover {
      transition: all .2s;
      background: lighten(#37c936, 10%);
    }
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
}