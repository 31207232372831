.table-money {
  .table-money_container {
    width: calc(100vw - 128px);
    overflow: hidden;
    height: calc(100vh - 160px);
    transition: all .3s;
    &.sidebar-visible {
      width: calc(100vw - 304px);
      transition: all .3s;
    }
  }
}