.login-screen {
  background-color: #f5f7fa;
}

.container-height_full {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
  .login-form_container {
    background: #fff;
    width: 100%;
    display: flex;
    min-height: 500px;
    box-shadow: 0 2px 18px 0 rgba(0, 21, 41, .075);
    .login-form_content {
      min-width: 400px;
      flex: 0 0 45%;
      max-width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-top: 10px solid #da4134;
      padding: 3.75rem 4rem 4rem;
      min-height: 400px;
      background-image: url('../../images/login/login-logo_bg.png');
      background-repeat: no-repeat;
      background-size: 90%;
      background-position: center 1rem;
      .login-form_logo {
        max-width: 235px;
        margin-bottom: 4rem;
      }
    }
    .login-form_background {
      flex: 1;
      max-width: 55%;
      position: relative;
      background: url('../../images/login/login-bg.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom center;
    }
  }
  .login-form {
    h6 {
      font-size: 1.3rem;
      font-weight: 700;
      margin-bottom: 2rem;
      color: #433637;
      font-family: Roboto, sans-serif;
    }
    .login-form-button_submit {
      background: #37c936;
      border-color: #37c936;
      padding: 0 32px;
      height: 36px;
      border-radius: 2px;
      font-family: Roboto, sans-serif;
      font-weight: 500;
      transition: all .2s;
      color: #fff;
      &:hover {
        background: lighten(#37c936, 10%);
        border-color: lighten(#37c936, 10%);
        transition: all .2s;
      }
    }
    .ant-form-item:first-of-type {
      margin-bottom: 16px;
    }
    .ant-form-item {
      margin-bottom: 2rem;
      &.with-error {
        margin-bottom: .5rem;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      .has-error .ant-input:focus {
        border-color: #ff4d4f;
        outline: 0;
        -webkit-box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
        box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
      }
      .has-error .ant-input, .has-error .ant-input:hover {
        border-color: #f5222d;
      }
      .ant-input {
        height: 38px;
        border: 2px solid #e6e7eb;
        border-radius: 2px;
      }
    }
    .login-form_heading {
      background: #ed515f;
      background-image: linear-gradient(281deg, #ed515f, #ff6f33);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 1rem 2rem;
      margin-bottom: 1rem;
      p {
        margin-bottom: 0;
        font-size: 1.15rem;
        color: #fff;
      }
    }
    .login-form_inputs {
      padding: 0 2rem 2rem;
      display: flex;
      flex-direction: column;
    }
    .ant-form-item-control {
      position: relative;
      margin-bottom: 8px;
      .ant-form-explain {
        position: absolute;
        left: 0;
        bottom: -18px;
      }
    }
    .login-form_input {
      color: #000;
      text-align: center;
      &:after {
        background: #ed515f;
        background-image: linear-gradient(281deg, #ed515f, #ff6f33);
      }
    }
    .login-form_label {
      color: rgba(0, 0, 0, 0.54);
    }
    .button-container {
      text-align: center;
    }
    .login-form_submit {
      padding: .75rem 3rem;
      margin-top: 1.5rem;
      border-radius: 25px;
      background: #ed515f;
      background-image: linear-gradient(281deg, #ed515f, #ff6f33);
      color: #fff;
      outline: none;
    }
    .text-error {
      color: #ff1744;
      font-size: 12px;
      margin: .5rem 0;
      text-align: left;
    }
  }
  .login-form-button_back {
    margin-top: 4rem;
    background: transparent;
    border: 1px solid #e6e7eb;
    font-weight: 400;
    padding: 0 16px;
    height: 36px;
    font-family: Roboto, sans-serif;
    color: #15191c;
    border-radius: 2px;
    line-height: 34px;
    &:hover {
      background: transparent;
      border: 1px solid #e6e7eb;
      color: #15191c;
      box-shadow: 0 1px 8px 0 rgba(0, 21, 41, 0.075);
      transition: all .2s;
    }
  }
  .copyright {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 52px;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    color: white;
  }
  .ant-input-affix-wrapper .ant-input-prefix {
    left: 2px;
    width: 36px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e6e7eb;
    color: #fff;
    i {
      color: #fff!important;
      font-size: 1.15rem;
    }
  }
  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 52px;
  }
}


