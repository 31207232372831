.page-notfound {
  min-height: 100vh;
  display: flex;
  .error-500 {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 24px;
    img {
      max-width: 100%;
      height: auto;
      margin-bottom: 48px;
    }
    h2 {
      color: #2a4558;
      font-size: 84px;
      font-weight: 700;
      line-height: 84px;
      margin-bottom: 16px;
      text-align: center;
    }
    p {
      color: rgba(0, 0, 0, .5);
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 24px;
    }
  }
}
