.table-expense {
  position: relative;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 160px);
  .table-expense_container {
    height: calc(100vh - 160px);
    width: 640px;
    overflow: hidden;
  }
}