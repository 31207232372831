.header {
  background: #ffffff;
  //background-image: linear-gradient(281deg, #ed515f, #ff6f33);
  min-height: 64px;
  padding: 0 0 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 12px 0 rgba(0, 21, 41, .1);
  width: calc(100vw - 80px);
  transition: all .2s;
  &.sidebar-visible {
    transition: all .2s;
    width: calc(100vw - 256px);
  }
  .header-userName {
    p {
      margin-bottom: 0;
      font-size: 1.35rem;
      color: #433637;
      font-weight: 500;
    }
  }
  .header-side_left {
    display: flex;
    align-items: center;
    .point-name {
      h6 {
        margin-bottom: 0;
        color: #433637;
        font-size: 1.5rem;
        font-weight: 500;
      }
    }
  }
  .header-settings {
    display: flex;
    align-items: center;
    .month-picker_container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .sidebar-hide {
      button {
        width: 64px;
        height: 64px;
        color: #a6aab4;
        border-radius: 0;
      }
      border-left: 1px solid #d4d4d4;
    }
  }

  .header-icon {
    color: #433637 !important;
    outline: none;
    border-radius: 21px;
    width: 42px;
    height: 42px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    &.header-icon_print {
      background-color: #effbef;
      transition: all .2s;
      margin-right: 16px;
      &:hover {
        background-color: darken(#effbef, 10%);
        transition: all .2s;
      }
    }
    &.header-icon_save {
      background-color: #f1f5ff;
      transition: all .2s;
      &:hover {
        background-color: darken(#f1f5ff, 10%);
        transition: all .2s;
      }
    }
    &.header-icon_logout {
      background-color: #fff1f1;
      transition: all .2s;
      margin-right: 16px;
      &:hover {
        background-color: darken(#fff1f1, 10%);
        transition: all .2s;
      }
    }
  }
  .sidebar-hide {
    .header-icon {
      font-size: 24px;
      color: #a6aab4 !important;
    }
  }
  .ant-calendar-picker-input.ant-input {
    text-align: center;
    border: none;
    background-color: transparent;
    width: 120px;
    color: #fff;
    font-weight: 400;
    font-size: 15px;
    padding: 0;
    outline: none;
    cursor: pointer;
    text-transform: capitalize;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .ant-calendar-picker.ant-calendar-picker-small {
    cursor: pointer;
    div {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      padding: 9px 18px 9px 24px;
      background-color: #37c936;
      border-radius: 4px;
      transition: all .2s;
      &:hover {
        transition: all .2s;
        background-color: lighten(#37c936, 10%);
      }
    }
    .ant-calendar-picker-icon {
      position: static;
      color: white;
      margin-top: -4px;
      font-size: 16px;
      margin-right: 2px;
    }
  }
  .badge-save {
    margin-right: 16px;
  }
  .ant-badge {
    display: flex;
    font-size: 18px;
    .ant-badge-dot {
      top: 4px;
      right: 4px;
      height: 6px;
      width: 6px;
      box-shadow: 0 0 0 2px #fff;
      overflow: visible;
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 2px solid #db1a24;
        content: '';
        -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
        animation: antStatusProcessing 1.2s infinite ease-in-out;
      }
    }
  }
}

.ant-calendar-month-panel-month {
  padding: 0 12px;
  border-radius: 4px;
  &:hover {
    background: rgba(55, 201, 54, .3);
  }
}

.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  background: #37c936;
  color: #fff !important;
  font-weight: 500;
  &:hover {
    background: #37c936;
  }
}

.month-picker_container {
  margin-right: 48px;
  button.month-button {
    padding: 0;
    min-height: 24px;

    &:focus {
      outline: none;
    }
  }
}

.pulse {
  z-index: 1;
  overflow: visible;
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #db1a24;
    border-radius: 26px;
    transition: opacity .35s, transform .35s;
    animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
    z-index: 0;
  }
}

@keyframes pulse-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(1.75);
  }
}