.table-main {
  position: relative;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 160px);
  .table-main_container {
    height: calc(100vh - 160px);
    width: 640px;
    overflow: hidden;
  }
  .data-grid {
    margin: auto;
    width: 100%;
    td.cell.read-only {
      color: #616161;
    }
    td.cell {
      text-align: center;
      span {
        padding: 2px 2px;
      }
    }
    td.cell > input {
      outline: none !important;
      border: 2px solid rgb(33, 133, 208);
      text-align: center;
      width: 100%;
      height: 100%;
      background: none;
    }
  }
  .button-activity-modal {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
    font-size: 24px;
    padding: 16px;
    width: 56px;
    height: 56px;
  }
}