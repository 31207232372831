@import '~antd/dist/antd.css';

@font-face {
  font-family: 'Roboto';
  src: url(./fonts/Roboto/Roboto-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url(./fonts/Roboto/Roboto-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url(./fonts/Roboto/Roboto-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

#root {
  min-height: 100vh;
  max-height: 100vh;
}

body, .ant-modal, div, table, h1, h2, h3, h4, h5, h6, p, input, button, span {
  font-family: 'Roboto', sans-serif !important;
}

.home-container {
  min-height: 100vh;
  max-height: 100vh;
  .ant-layout {
    min-height: 100vh;
    max-height: 100vh;
  }
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color .3s;
    &:hover {
      color: #1890ff;
    }
  }
}

.button-gradient {
  height: 40px;
  font-family: "Proxima Nova", sans-serif;
  background: linear-gradient(to right, #e9872f 0%, #e15f2e 50%, #e9872f 100%);
  background-size: 200%;
  background-position: 0;
  border: none;
  color: white;
  font-weight: 400;
  box-shadow: 1px 5px 10px 0px rgba(68, 18, 12, 0.2);
  border-radius: 4px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover, &:focus {
    transition: all .2s;
    background: linear-gradient(to right, #e9872f 0%, #e15f2e 50%, #e9872f 100%);
    background-size: 200%;
    background-position: 100%;
    color: white;
  }
}

.handsontable th, .handsontable td {
  font-size: 13px;
}