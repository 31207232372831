
.sidebar {
  background: #fff !important;
  box-shadow: 0 0 12px 0 rgba(0, 21, 41, 0.07);
  p {
    color: #fff;
    &.userName {
      font-weight: 300;
      font-size: 1.35rem;
      margin-bottom: 0;
    }
    &.userRole {
      font-weight: 300;
      font-size: 1.1rem;
      margin-bottom: 0;
    }
  }
  .sidebar-logo {
    height: 64px;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #da2d2c;
  }
  .sidebar-header {
    padding: 1rem;
    border-bottom: 1px solid #1c262f;
  }
  .sidebar-points {
    .sidebar-point {
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all .2s;
      border-bottom: 1px solid #1c262f;
      p {
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: .85rem;
        font-weight: 500;
      }
      &:hover {
        background-color: lighten(#2e3d49, 10%);
        transition: all .2s;
      }
    }
  }
  .sidebar-owner {
    width: 100%;
    opacity: 1;
    height: 96px;
    transition: all .5s;
    white-space: nowrap;
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    h6 {
      color: #433637;
      font-size: 16px;
      margin-bottom: 0;
    }
    p {
      color: #767c8c;
      font-size: 12px;
    }
    .owner-info {
      width: 100%;
      border-bottom: 1px solid #e6e7eb;
    }
  }
  &.ant-layout-sider-collapsed {
    .sidebar-owner {
      opacity: 0;
      width: 0;
      transition: all .3s;
      overflow: hidden;
    }
  }
  .ant-menu-vertical > .ant-menu-item {
    line-height: 52px;
    height: 52px;
  }
  .ant-menu-inline .ant-menu-item {
    line-height: 52px;
    height: 52px;
    font-size: 14px;
    .anticon {
      color: #a6aab4;
    }
    span {
      color: #767c8c;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #fbe8e9;
  }
  .ant-menu-item-selected.ant-menu-item {
    color: #db1a24;
    .anticon {
      color: #db1a24;
    }
    span {
      color: #db1a24;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .ant-menu-vertical .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-inline .ant-menu-item:after {
    content: "";
    position: absolute;
    left: 0;
    right: auto;
    top: 0;
    bottom: 0;
    border-right: 3px solid #db1a24;
  }

  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: none;
  }

  .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
    color: #db1a24;
  }

  .ant-menu-inline-collapsed > .ant-menu-item .anticon, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
    font-size: 18px;
    line-height: 40px;
    margin: 0;
  }

}