.table-orders {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 160px);
  .table-orders_container {
    position: relative;
    width: calc(100vw - 128px);
    overflow: hidden;
    height: calc(100vh - 160px);
    transition: all .3s;
    &.sidebar-visible {
      width: calc(100vw - 304px);
      transition: all .3s;
    }
    .colorized-red {
      background-color: #e57373;
    }
    .colorized-green {
      background-color: #81c784;
    }
    .colorized-yellow {
      background-color: #fff176;
    }
    .text-black {
      color: #000;
    }
    .d-flex {
      display: flex;
    }
    .flex-column {
      flex-direction: column;
    }
    .mb-4 {
      margin-bottom: 2rem;
    }
    .table-orders_actions {
      position: absolute;
      bottom: 36px;
      right: 36px;
      display: flex;
      flex-direction: column;
      .modal-order_add-container {
        &:not(:last-of-type) {
          margin-bottom: 24px;
        }
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 24px;
        background: #37c936;
        font-size: 20px;
        border: 1px solid #37c936;
        box-shadow: 0 0 0 3px #fff, 0 0 0 4px rgba(55, 201, 54, .4);
      }
    }
  }
  .table-orders_empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    button {
      background: #37c936;
      border: 1px solid #37c936;
      color: #fff;
      border-radius: 4px;
      outline: none;
      padding: 0 32px;
      text-transform: none;
      font-size: 16px;
      font-weight: 400;
      height: 48px;
      &:hover {
        background: lighten(#37c936, 10%);
      }
      &.button-copy-providers {
        margin-top: 32px;
      }
    }
  }
}