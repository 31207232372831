.error-boundary {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 24px;
  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 48px;
  }
  h2 {
    color: #2a4558;
    font-size: 72px;
    font-weight: 600;
    line-height: 72px;
    margin-bottom: 24px;
  }
  p {
    color: rgba(0, 0, 0, .5);
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 24px;
  }
}